import {
  blackColor,
  purpleColor,
  grayColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const listAndImageAreaStyles = theme => ({
  listContent: {
  },
  listItem: {
    color: blackColor,
    "&:hover,&:focus": {
      color: purpleColor[0],
      cursor: "pointer"
    },
    paddingLeft: "0",
    paddingRight: "0",
  },
  selectedListItem: {
    color: purpleColor[0],
    "&:hover,&:focus": {
      color: purpleColor[0],
      cursor: "pointer"
    },
    paddingLeft: "0",
    paddingRight: "0",
  },
  primaryText: {
    color: "inherit",
    margin: "0",
    marginTop: "0",
    marginBottom: "0",
    fontSize:"2.1em",
    fontWeight: "500",
    textAlign: "left",
    textDecoration: "none",
  },
  secondaryText: {
    color: blackColor,
    marginTop: "-2px",
    fontSize: "1.25em",
    fontWeight: "300",
    lineHeight: "1.4em",
  },
  icon: {
    color: "inherit",
    marginRight: "4px",
    width: "48px",
    height: "48px",
  },
  gridItem: {
    height: "480px",
    [theme.breakpoints.down("sm")]: {
      height: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      height: "560px",
    }
  },
  backgroundImage: {
    padding: "32px 24px",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "contain",
    },
  },
  image: {
    transform: "translate(7%, 0%)",
    maxWidth: "380px",
    borderStyle: "solid",
    borderColor: grayColor[10],
    borderTop: "1px",
    borderRadius: "5px",
    verticalAlign: "middle",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "640px",
    },
  },
});

export default listAndImageAreaStyles;
