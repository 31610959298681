import React, {  Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import WebLayout from 'layouts/WebLayout/WebLayout';

class App extends Component {
	
	componentDidMount() {
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.props.location.pathname === "/" 
					&& prevProps.location.pathname.startsWith(process.env.REACT_APP_BROKER_PLATFORM)) {
			this.props.checkAuthorized();
		}
		// scroll to top on a new page
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const panel = document.getElementById("adminMainPanel");
			if(panel) {
				panel.scrollTo(0,0);
			}
    }
	}

	render() {		
		return (
			<div>
				<Switch>
					<Route path={process.env.REACT_APP_EXASPACES_WEBSITE} component={WebLayout} />
					<Redirect from="/" to={`${process.env.REACT_APP_EXASPACES_WEBSITE}/platform`} />
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
	};
};

const mapDispatchToProps = dispatch => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));