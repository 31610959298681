import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import propertyTypesIcon from "assets/img/platform/broker/transaction/section1-property-types.svg";
import attributesIcon from "assets/img/platform/broker/transaction/section1-property-attributes.svg";
import documentationIcon from "assets/img/platform/broker/transaction/section1-documentation.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"Grow your business by standardizing your client & landlord data, document all your interactions"}
      items={[
        {
          image: propertyTypesIcon,
          title: "Property types",
          description: "Capture baseline requirements including location, space and price preference"
        },
        {
          image: attributesIcon,
          title: "Property attributes",
          description: "Capture facilities and utilities information from a set of standard values, create custom data points based on need"
        },
        {
          image: documentationIcon,
          title: "Documentation",
          description: "Capture all types of documents e.g. comprehensive requirement document, interior expectations, etc."
        },
      ]}
    />
  );
}