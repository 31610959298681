import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";
import Card from "core/components/Card/Card";
import CardBody from "core/components/Card/CardBody";
import CardHeader from "core/components/Card/CardHeader";

// echelon components
import InfoCard from "views/Website/components/InfoCard/InfoCard";

// images for the section
import logo from "core/assets/img/exaspaces.png"
import backgroundImage from "assets/img/vision/section1-image1.png";
import clientImage from "assets/img/vision/section1-clients.svg";
import brokerImage from "assets/img/vision/section1-brokers.svg";
import ownersImage from "assets/img/vision/section1-owners.svg";
import serviceProviderImage from "assets/img/vision/section1-serviceproviders.svg";

import visionSectionStyles from "assets/jss/material-dashboard-pro-react/views/website/visionSectionStyles.js";
const useStyles = makeStyles(visionSectionStyles);

export default function ExploreExaspaces(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.underline}>
        <span className={classes.text}>Explore</span><span className={classes.colortext}>EXASPACES</span>
      </div>

      <div className={classes.container}>
        <Card plain>
          <CardHeader 
            className={classes.logoHeader}
            style={{backgroundImage: "url(" + backgroundImage + ")" }}>
              <div className={classes.logoContainer}>
                <div className={classes.circle}>
                  <img src={logo} alt={"Exaspaces logo"} className={classes.logo}/>
                </div>
              </div>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <InfoCard
                  image={clientImage}
                  header={"Clients"}
                  contents={"The consumers (buy, lease or rent) of real estate properties (Retail space, Office space, Industrial space, Co-working space, Land)"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <InfoCard
                  image={brokerImage}
                  header={"Brokers"}
                  contents={"The deal makers; bring clients and owners together on the same platform to facilitate a deal"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <InfoCard
                  image={ownersImage}
                  header={"Owners"}
                  contents={"The owners, developers of real estate properties (Retail space, Office space, Industrial space, Co-working space, Land)"}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <InfoCard
                  image={serviceProviderImage}
                  header={"Service providers"}
                  contents={"The universe around the core pillars, offering a wide array of services for the industry (e.g. Interior designers, Architects, PMCs, MEP, etc.)"}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}