import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  error: null,
};
  
const started = (state, action) => {
  return update( state, {
    error: null,
    modifiedDate: -1,
  });
};

const succeeded = (state, action) => {
  return update( state, {
    error: null,
    modifiedDate: new Date(),
  });
};

const failed = (state, action) => {
  return update( state, {
    error: action.error,
    modifiedDate: new Date(),
  });
}

const reducer = (state = initial, action) => {
  switch ( action.type ) {
    case types.ENQUIRY_START: 
      return started(state, action);

    case types.ENQUIRY_SUCCESS: 
      return succeeded(state, action);

    case types.ENQUIRY_FAILURE: 
      return failed(state, action);

    default:
      return state;
  }
};

export default reducer;