import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import DemoView from "./DemoView"

// echelon commons/utilities
import { updatestate } from 'variables/utilities';

import * as notice from "store/actions/notice";
import * as demo from "store/actions/demo";

class Demo extends Component {
	constructor(props) {
		super(props);
		
		// state related data container
		this.state = {
			basic: {
        name: '',
        companyName: '',
				email: '',
        phoneNo: '',
			},

			// state management
			valid: {},
		}
	}

  onChange = (event, attribute, type, valid=true) => {
		this.setState(updatestate(this.state, event, attribute, type, valid));
  }

	onSubmit = ({ token, purpose, version }) => {
		// perform the necessary validation and show the error message if required
    const valid = this.validate();
    const proceed = Object.values(valid).find((value) => value === false);
		if(proceed === false) {
      this.setState({valid: valid});
			this.props.notify("999");
			
			return;
		}

		const { basic } = this.state;
		this.props.requestdemo({...basic, token: token, purpose: purpose, version:version});
		this.props.history.push("/website");
	}

	validate = () => {
		const { basic, valid } = this.state;
		if(basic.email === '') {
			valid.email = false;
		}

		if(basic.phoneNo === '') {
			valid.phoneNo = false;
		}

		if(basic.companyName === '') {
			valid.companyName = false;
		}

		if(basic.name === '') {
			valid.name = false;
		}
		
		return valid;			
	}

	// the view of the component that needs to be rendered
	render() {
		const { basic, valid } = this.state;
		return (
			<div>
				<DemoView
					{...this.props}
					basic={basic}
					valid={valid}
					onChangeHandler={this.onChange}
					onSubmitHandler={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		demo: state.demo.demo,
		modifiedDate: state.demo.modifiedDate,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		notify: (code) => dispatch(notice.notifyFailure(code)),
		requestdemo: (body) => dispatch(demo.requestdemo(body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Demo);  