import { NOTICE_SHOW, NOTICE_HIDE } from 'store/types';

export const notifyFailure = (code) => {
  return dispatch => {
    dispatch({
        type: NOTICE_SHOW,
        code: code,
        color: "danger"
    });
  };
};

export const show = (code, color="success") => {
  return dispatch => {
    dispatch({
        type: NOTICE_SHOW,
        code: code,
        color: color
    });
  };
};

export const hide = () => {
  return dispatch => {
    dispatch({
        type: NOTICE_HIDE,
    });
  };
};
