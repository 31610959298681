import {
  blackColor,
  purpleColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const whoareweSectionStyles = theme => ({
  section: {
    color: blackColor,
    backgroundColor: purpleColor[0],
  },
});

export default whoareweSectionStyles;