import React from "react";
import PropTypes from "prop-types";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AutocompleteEntity from "./AutocompleteEntity";

const filter = createFilterOptions();
export default function AutocompleteTags(props) {
  const {labelText, limitTags=-1, clearText="Remove all tags", options, multiple=true, value, ...rest } = props

  const onChange = (event, tags, reason) => {
    let filtered = tags;
    if(['enter', 'select-option'].includes(reason)) {
      if(multiple === false) {
        filtered = tags.inputValue ? tags.inputValue : tags;
      } else {
        filtered = tags.map((tag) => {
          return tag.inputValue ? tag.inputValue : tag;
        });
      }
    }
    props.onChange(event, filtered, reason);
  }

  const onKeyDown = (e) => {
    if(e.keyCode === 13 && e.target.value) {
      onChange(e, multiple ? [...value, e.target.value] : e.target.value, 'enter')
    }
  }

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  return (
    <AutocompleteEntity 
      {...rest}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple={multiple}
      limitTags={limitTags}
      labelText={labelText}
      clearText={clearText}
      options={options}
      value={value}
      filterOptions={filterOptions}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}

AutocompleteTags.propTypes = {
  options: PropTypes.array.isRequired,
  labelText: PropTypes.string,
  clearText: PropTypes.string,
  limitTags: PropTypes.number,
}