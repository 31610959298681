/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button } from "@material-ui/core";

// @material-ui/core icons
import LinkedInLogo from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PhoneIcon from "@material-ui/icons/PhoneOutlined"
import EmailIcon from "@material-ui/icons/EmailOutlined"

// core components
import Logo from "core/assets/img/exaspaces.full.png";

import styles from "core/assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import { Fragment } from "react";
const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, website=false } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  return (
    <footer className={website ? classes.websitefooter :classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <img className={website ? classes.websitelogo : classes.logo} src={Logo} alt={"Logo"} />
          {website &&
            <p className={classes.leftText}>Headquartered in Chennai, India. Exaspaces provides the most streamlined, 
                    easy-to-use suite of products for the Real Estate industry.
            </p>
          }
        </div>
        <div className={classes.right}>
          <IconButton 
            className={classes.linkedinIcon}
            alt="Exaspaces on LinkedIn"
            href="https://www.linkedin.com/showcase/exaspaces-broker-platform"
            rel="noopener noreferrer" 
            target="_blank">
            <LinkedInLogo fontSize={website ? "large" : "small"} />
          </IconButton>

          <IconButton 
            className={classes.facebookIcon}
            alt="Exaspaces on Facebook"
            href="https://fb.me/exaspaces"
            rel="noopener noreferrer"
            target="_blank">
            <FacebookIcon fontSize={website ? "large" : "small"} />
          </IconButton>

          {/* <IconButton className={classes.twitterIcon}
            href="#"
            rel="noopener noreferrer"
            target="_blank">
            <TwitterIcon fontSize={website ? "large" : "small"} />
          </IconButton> */}
          
          {website &&
            <Fragment>
              <p className={classes.buttonContainer}>
                <Button
                  href={`tel:${process.env.REACT_APP_API_MOBILE}`}
                  startIcon={<PhoneIcon fontSize="large"  className={classes.buttonIcon} />}
                  className={classes.buttonText}>
                    {process.env.REACT_APP_API_MOBILE}
                </Button>
              </p>
              <p className={classes.buttonContainer}>
                <Button
                  href={"mailto:sales@exaspaces.com?subject=Request for more information"}
                  startIcon={<EmailIcon fontSize="large" className={classes.buttonIcon} />}
                  className={classes.buttonText}>
                    sales@exaspaces.com
                </Button>
              </p>
            </Fragment>
          }
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
