import React from "react";
import classNames from "classnames";

// @material-ui components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, TextField } from "@material-ui/core";

import styles from "common/assets/jss/material-dashboard-pro-react/views/autocompleteEntityViewStyle.js";
const useStyles = makeStyles(styles);

export default function AutocompleteEntity(props) {
  const { onChange, onKeyDown,
          formControlProps, inputRootCustomClasses, error, success, white, options,
          labelText, placeholder="Type here to choose...", id="autocomplete-", value, optionKey,
          disabled=false, fullWidth=true, limitTags, multiple=true, compact=false, ...rest} = props;
  const classes = useStyles();

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames({
      [formControlProps.className]: formControlProps.className,
      [classes.formControl]: !compact
    });
  } else {
    formControlClasses = classNames({
      [classes.formControl]: !compact
    });
  }

  const getOptionSelected = (option, value) => {
    if(optionKey) {
      return value[optionKey] === option[optionKey];
    }
    return option === value;
	}
 
  const getOptionLabel = (option) => {
    if(props.getOptionLabel) {
      return props.getOptionLabel(option);
    } 
    return option.name ?  option.name : option;
  }

  return (
    <Autocomplete
      {...rest}
      id={id + "autocomplete-standard"}
      disabled={disabled}
      fullWidth={fullWidth}
      multiple={multiple}
      limitTags={limitTags}
      options={options}
      size={'small'}
      value={value}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
        <TextField
          {...params}
          variant="standard"
          label={labelText}
          error={error}
          autoComplete="new-password"
          placeholder={placeholder}
          InputLabelProps={{...params.InputLabelProps, className: (classes.labelRoot + " " + labelClasses)}}
          InputProps={{...params.InputProps, classes: {
						input: inputClasses,
						root: marginTop,
						disabled: classes.disabled,
						underline: underlineClasses
          }}}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password"
          }}
          onKeyDown={onKeyDown}
          className={formControlClasses}
        />
      )}}
      getOptionSelected={getOptionSelected}
      onChange={(event, value, reason)=> onChange(event, value, reason)}
      autoComplete={false}
      ChipProps={{className: classes.chip}}
    />
  );
}