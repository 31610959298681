import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Business from "@material-ui/icons/Business";
import Key from "@material-ui/icons/VpnKey";
import Lock from "@material-ui/icons/LockOutlined";
import NameIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import Button from "core/components/CustomButtons/Button.js";
import CustomInput from "core/components/CustomInput/CustomInput.js";
import Card from "core/components/Card/Card.js";
import CardHeader from "core/components/Card/CardHeader.js";
import CardBody from "core/components/Card/CardBody.js";
import Address from "core/components/Address/Address";

import backgroundImage from "assets/img/login-background.jpeg";

import CardFooter from "core/components/Card/CardFooter";
import RecaptchaButton from "common/components/Captcha/RecaptchaButton";
import AutocompleteTags from "common/components/Autocomplete/AutocompleteTags";

import { verifyEmail, verifyPhone, 
        verifyPassword, verifyLength, useTitle } from "variables/utilities.js";
import { PROPERTY_TYPES } from "variables/statics";

import styles from "core/assets/jss/material-dashboard-pro-react/views/registerPageStyle";
const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  useTitle("Exaspaces, sign up for access to the platform");

  const { basic, valid, showotp, manualcaptcha=false, onChangeHandler, onSubmitHandler } = props
  const [checked, setChecked] = React.useState([]);
  const BUSINESS_CATEGORY = PROPERTY_TYPES;

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 400);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const onPlaceChanged = (address, formatted) => {
    onChangeHandler(address, 'address', 'basic', true);
  }

  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.fullPage}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "inherit",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={9} md={7} lg={6}>
              <form autoFocus>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}>
                    <h1 className={classes.cardH2Title}>{props.freetrial ? 
                    "Try Exaspaces for free" : "Register with Exaspaces" } </h1>
                    <h5 className={classes.cardTitle}>Broker Platform</h5>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={7}>
                      <CustomInput
                          id="email"
                          labelText="Email"
                          success={valid.email === true}
                          error={valid.email === false}
                          formControlProps={{
                            fullWidth: true,
                            required: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange: event => {
                              const valid = verifyEmail(event.target.value);
                              onChangeHandler(event, 'email', 'basic', valid);
                            },
                            value: basic.email,
                            disabled: showotp === true
                          }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Phone no."
                        error={valid.phoneNo === false}
                        success={valid.phoneNo === true}
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyPhone(event.target.value);
                            onChangeHandler(event, 'phoneNo', 'basic', valid);
                          },
                          value: basic.phoneNo,
                        }}
                      />

                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Name"
                        success={valid.createdByName === true}
                        error={valid.createdByName === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <NameIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyLength(event.target.value, 1);
                            onChangeHandler(event, 'createdByName', 'basic', valid);
                          },
                          value: basic.createdByName,
                          disabled: showotp === true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="name"
                        labelText="Company Name"
                        success={valid.name === true}
                        error={valid.name === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Business className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyLength(event.target.value, 1);
                            onChangeHandler(event, 'name', 'basic', valid);
                          },
                          value: basic.name,
                          disabled: showotp === true
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <AutocompleteTags
                        labelText="Business category *"
                        clearText="Clear all values"
                        options={BUSINESS_CATEGORY}
                        value={basic.category}
                        fullWidth
                        error={valid.category === false}
                        formControlProps={{
                          required: true,
                        }}
                        onChange={(event, tags, reason) => {
                          const valid = tags && tags.length > 0 ? true : false;
                          onChangeHandler(tags, 'category', 'basic', valid);
                        }}
                      />
                    </GridItem>	

                    <GridItem xs={12} sm={12} md={12}>
                      <Address 
                        id="address"
                        labelText="City *"
                        fullAddress={false}
                        success={valid.address === true}
                        error={valid.address === false}
                        onPlaceChanged={onPlaceChanged}
                        inputProps={{
                          disabled: showotp === true
                        }}
                      />
                        
                      <CustomInput
                        id="password"
                        labelText="Password"
                        success={valid.password === true}
                        error={valid.password === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Lock className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyPassword(event.target.value);
                            onChangeHandler(event, 'password', 'basic', valid);
                          },
                          disabled: showotp === true,
                          value: basic.password,
                          type: "password",
                          autoComplete: "off"
                        }}
                        helperText={!showotp ? "Passwords must be minimum 6 characters long, with at least 1 numeric, 1 uppercase and 1 lowercase character" : ""}
                      />

                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <a href={process.env.REACT_APP_TOS} target="_blank" rel="noopener noreferrer">Terms and conditions</a>
                            {" "} and {" "}
                            <a href={process.env.REACT_APP_PP} target="_blank" rel="noopener noreferrer">Privacy policy</a>
                          </span>
                        }
                      />

                      {showotp && (
                        <div className={classes.center}>
                        <CustomInput
                          id="code"
                          labelText="Activation code"
                          success={valid.code === true}
                          error={valid.code === false}
                            formControlProps={{
                            fullWidth: false,
                            required: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Key className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange: event => {
                              const valid = verifyLength(event.target.value, 6);
                              onChangeHandler(event, 'code', 'basic', valid);
                            },
                            value: basic.code,
                            type: "password",
                            autoComplete: "off"
                          }}
                        />
                        </div>)
                      }

                      <div className={classes.center}>
                        <RecaptchaButton
                          Button={Button}
                          manualcaptcha={manualcaptcha}
                          round color="purple"
                          disabled={checked.length === 0}
                          onClick={onSubmitHandler}>
                            {!showotp ? "GET OTP" : "SUBMIT REQUEST"}
                        </RecaptchaButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  {!manualcaptcha &&
                  <div>
                    This site is protected by reCAPTCHA and the Google 
                    <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank"> Privacy Policy </a> and 
                    <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank"> Terms of Service </a> apply.
                  </div>
                  }
                </CardFooter>
              </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
