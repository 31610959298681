import { NOTICE_SHOW, NOTICE_HIDE } from 'store/types';
import { update } from 'variables/utilities';

const initial = {
  notify: false, code: null, color: null,
};

const show = (state, action) => {
  return update( state, {
    notify: true,
    code: action.code ? action.code : state.code,
    color: action.color ? action.color : state.color,
  });
};

const hide = (state, action) => {
  return update( state, {
    notify: false,
    code: action.code ? action.code : state.code,
    color: action.color ? action.color : state.color,
  });
};


const notice = (state = initial, action) => {
  switch ( action.type ) {
      case NOTICE_SHOW: 
          return show(state, action);

      case NOTICE_HIDE: 
          return hide(state, action);

      default:
          return state;
  }
};

export default notice;