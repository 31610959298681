import {
  container,
  title,
  whiteColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const landingPageStyle = theme => ({
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    color: whiteColor,
    minHeight: "32px",
    fontWeight: "600",
    textDecoration: "none",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70%",
    },
    marginTop: "0px",
  },
  titleMargin:{
    [theme.breakpoints.down("md")]: {
      marginTop: "72px",
      maxWidth: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "72px",
      maxWidth: "70%",
    },
  },
  titleLanding: {
    [theme.breakpoints.up("lg")]: {
      marginTop: "72px",
      maxWidth: "60%",
    },
  },
  subtitle: {
    marginTop: "-16px",
    fontSize: "1.1rem",
    fontWeight: "400",
    maxWidth: "500px",
    lineHeight: "1.5rem"
  },
  textBold: {
    marginTop: "24px",
    zIndex: 99,
    fontWeight: "700"
  },
});

export default landingPageStyle;
