import {
  blackColor,
  purpleColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const visionSectionStyles = theme => ({
  section: {
    // textAlign: "center"
  },
  text: {
    color: blackColor,
    fontSize: "3em",
    fontWeight: "600",
    lineHeight: "2.1em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5em",
    },
  },
  colortext: {
    color: purpleColor[0],
    fontSize: "3em",
    fontWeight: "600",
    lineHeight: "2.1em",
    marginLeft: "12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5em",
    },
  }, 
  underline: {
    textAlign: "left",
    marginTop: "24px",
    borderBottomColor: purpleColor[0],
    borderBottomStyle: "solid",
    borderBottom: "3px"
  },
  container: {
    marginTop: "96px",
  },
  logoHeader: {
    justifyContent: "center",
    zIndex: "1",
    minHeight: "268px",
    borderRadius: "50px !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
  },
  circle: {
    marginTop: "-80px",
    width: "162px",
    height: "162px",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  logo: {
    width: "160px",
    height: "160px",
  },
  cardBody: {
    zIndex: "4",
    margin: "32px",
    marginTop: "-160px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
      marginTop: "-160px",
    },
  },
});

export default visionSectionStyles;