import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT
});

instance.interceptors.request.use((config) => {

	// add the content type header
	let contentType = 'application/json';
	const { geoheaders, urlencoded, multipart, apptype=true } = config; 

	if(urlencoded) {
		contentType = 'application/x-www-form-urlencoded';
	}
	if(multipart) {
		contentType = 'multipart/form-data';
	}
	config.headers['Content-Type'] = contentType;

	if(geoheaders) {
		Object.keys(geoheaders).map((key) => {
			config.headers[key]=geoheaders[key];
			return key;
		});
	}
	if(apptype) {
		config.headers['Xspaces-App'] = 'ebp';
	}
	// add the bearer token, if present																											
	const token = localStorage.getItem("token");
	if(token && token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

export default instance;