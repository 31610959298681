import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "views/Website/components/Section/BackgroundSection";
import SectionTop from "./Sections/SectionTop";
import SectionBottom from "./Sections/SectionBottom";

import style from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(style);

export default function Engagement({ ...rest }) {

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        marginTop={false}
        className={classes.backgroundEngagement}
        image={require("assets/img/landing/section1-image4.webp")} 
        title="The right software you need for managing property business"
        subtitle="The minimalist approach to solving a non-trivial problem on hand, customer data management and engagement."
        crumbs={[{crumbname: "Broker platform", crumbpath: "/website/platform", state: {scroll: false}}, 
                  {crumbname: "The right software you need for managing property business"}]}
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.striped}>
          <div className={classes.container}>
            <SectionTop />
          </div>
        </div>
        <div className={classes.container}>
          <SectionBottom />
        </div>
      </div>
    </div>
  );
}
