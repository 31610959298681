// Imports
import React, { Component } from 'react';

//Import React script Libraray to load Google object
import Script from "react-load-script";

export default function Recaptcha (props) {
  const { wait=5000 } = props;
  const [load, setLoad] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setLoad(true)
    }, wait);
  });

  if(load) {
    return (
      <RecaptchaLoader {...props} />
    );
  }

  return <></>;
}

class RecaptchaLoader extends Component {
  constructor(props) {
    super(props);

    this.state= {
      key: '6LcMZiUcAAAAAPNDC-bKt7ecxJB-1R8Vfdy8-647',
      ready: false,
    }
  }

  handleScriptLoad = () => {
    if(!window.grecaptcha) {
      console.warn("Google recaptcha script is not loaded or not available");
    }

    window.grecaptcha.ready(() => {
      this.setState({ready: true});
    })
  }

  render() {
    const { key } = this.state;
    return (
      <div>
        <Script
          attributes={{ id: "recaptcha-script"}}
          url={"https://www.google.com/recaptcha/api.js?render=" + key}
          onLoad={this.handleScriptLoad}
        />
      </div>
    )
  }
}