import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import brokerProposalImage from "assets/img/platform/broker/fasttimevalue/section1-broker-proposal.svg";
import clientProposalImage from "assets/img/platform/broker/fasttimevalue/section1-client-proposal.svg";
import recommendationsImage from "assets/img/platform/broker/fasttimevalue/section1-search-recommendations.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"See the world through your customer’s eyes"}
      items={[
        {
          image: clientProposalImage,
          title: "Client proposal",
          description: "Allow your client to consume, respond and collaborate with you"
        },
        {
          image: brokerProposalImage,
          title: "Broker proposal",
          description: "Analyze, Prepare and Share comprehensive proposals using property information"
        },
        {
          image: recommendationsImage,
          title: "Search & recommend",
          description: "View and act on contextual and relevant recommendations for your proposals"
        },
      ]}
    />
  );
}