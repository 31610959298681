import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";
import Card from "core/components/Card/Card";
import CardBody from "core/components/Card/CardBody";
import CardHeader from "core/components/Card/CardHeader";
import CardFooter from "core/components/Card/CardFooter";

import visionSectionStyles from "assets/jss/material-dashboard-pro-react/components/infoCardStyles";
const useStyles = makeStyles(visionSectionStyles);

export default function InfoCard (props) {
  const { image, header, contents, raised } = props;

  const classes = useStyles();
  return (
    <Card raised={raised}>
      <CardHeader>
        <GridContainer alignItems="center">
          <GridItem xs={4} sm={3} md={2}>
            <img src={image} className={classes.image} alt={header}/>
          </GridItem>
          <GridItem xs={8} sm={9} md={10}>
            <div className={classes.title}>{header}</div>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <div className={classes.cardBody}>{contents}</div>
      </CardBody>
      <CardFooter />
    </Card>
  )
}
