import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
  purpleColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

const exploreStyle = theme => ({
  block: {},
  left: {
    float: "left!important",
    display: "block",
    marginLeft: "24px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "72%",
      marginLeft: "56px",
    }
  },
  leftHeading: {
    color: whiteColor,
    fontWeight: "600",
    marginTop: "16px",
    marginBottom: "16px",
  },
  leftText: {
    color: whiteColor,
    fontSize: "18px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
      lineHeight: "22px"
    },
  },
  right: {
    margin: "0",
    fontSize: "14px",
    padding: "32px",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      alignContent: "center"
    },
    [theme.breakpoints.up("md")]: {
      float: "right !important",
      maxWidth: "25%",
    }
  },
  button: {
    fontWeight: "600",
    color: purpleColor[0]
  },
  footer: {
    bottom: "0",
    padding: "0",
    borderTop: "1px solid " + grayColor[15],
    minHeight: "76px",
    backgroundColor: "#8C70F8",
    ...defaultFont,
    zIndex: 4
  },
  container: {
    zIndex: 6,
    ...container,
    marginLeft: "none",
    position: "relative",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  containerFluid: {
    zIndex: 1,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
  }
});

export default exploreStyle;
