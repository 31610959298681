import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import NameIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import Button from "core/components/CustomButtons/Button.js";
import CustomInput from "core/components/CustomInput/CustomInput.js";
import Card from "core/components/Card/Card.js";
import CardHeader from "core/components/Card/CardHeader.js";
import CardBody from "core/components/Card/CardBody.js";

import backgroundImage from "assets/img/login-background.jpeg";

import CardFooter from "core/components/Card/CardFooter";
import RecaptchaButton from "common/components/Captcha/RecaptchaButton";

import {verifyEmail, verifyPhone, verifyLength} from "variables/utilities.js";
import { useTitle } from "variables/utilities";

import styles from "core/assets/jss/material-dashboard-pro-react/views/registerPageStyle";
const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  useTitle("Exaspaces, requet a demo of the platform");

  const { basic, valid, manualcaptcha=false, onChangeHandler, onSubmitHandler } = props

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 400);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });


  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.fullPage}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "inherit",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form autoFocus>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}>
                    <h1 className={classes.cardH2Title}>Request Demo</h1>
                    <h5 className={classes.cardTitle}>Broker Platform</h5>
                  </CardHeader>
                <CardBody semicompact>
                  <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={11}>
                       <CustomInput
                        labelText="Name"
                        error={valid.name === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <NameIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyLength(event.target.value, 1);
                            onChangeHandler(event, 'name', 'basic', valid);
                          },
                          value: basic.name,
                        }}
                      />

                      <CustomInput
                        labelText="Your company name"
                        error={valid.companyName === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CompanyIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyLength(event.target.value, 1);
                            onChangeHandler(event, 'companyName', 'basic', valid);
                          },
                          value: basic.companyName,
                        }}
                      />

                      <CustomInput
                        labelText="Email"
                        error={valid.email === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyEmail(event.target.value);
                            onChangeHandler(event, 'email', 'basic', valid);
                          },
                          value: basic.email,
                        }}
                      />

                      <CustomInput
                        labelText="Phone no."
                        error={valid.phoneNo === false}
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: event => {
                            const valid = verifyPhone(event.target.value);
                            onChangeHandler(event, 'phoneNo', 'basic', valid);
                          },
                          value: basic.phoneNo,
                        }}
                      />

                      <div className={classes.center}>
                        <RecaptchaButton
                          Button={Button}
                          manualcaptcha={manualcaptcha}
                          purpose="requestdemo"
                          round color="purple"
                          onClick={onSubmitHandler}>
                            Submit
                        </RecaptchaButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  {!manualcaptcha &&
                  <div>
                    This site is protected by reCAPTCHA and the Google 
                    <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank"> Privacy Policy </a> and 
                    <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank"> Terms of Service </a> apply.
                  </div>
                  }
                </CardFooter>
              </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
