import axios from "store/axios-broker";
import * as types from "store/types.js";

const started = (request) => {
  return {
    type: types.CONFIRMREGISTER_START,
    request: request
  };
};

const succeeded = () => {
  return {
    type: types.CONFIRMREGISTER_SUCCESS,
  };
};

const failed = (error) => {
  return {
    type: types.CONFIRMREGISTER_FAILURE,
    error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

const querystring = require('querystring');
export const confirm = (businessId, body) => {
  return dispatch => {
    dispatch(started('confirm'));
    axios.post("/apex/business/enroll/" + businessId + "/confirm",
                querystring.stringify(body), { urlencoded: true }
    ).then(response => {
      dispatch(succeeded());
    }).catch(error => {
      dispatch(failed(error.response));
    });
  };
};