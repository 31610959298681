import axios from "store/axios-broker";
import * as types from "store/types.js";
import { show as notifySuccess } from "store/actions/notice";

const started = (request) => {
  return {
    type: types.REGISTER_START,
    request: request
  };
};

const succeeded = (business) => {
  return {
    type: types.REGISTER_SUCCESS,
    business: business
  };
};

const failed = (error) => {
  return {
    type: types.REGISTER_FAILURE,
    error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

export const register = (body) => {
  return dispatch => {
    dispatch(started('register'));
    axios.post("/apex/business/enroll", body)
    .then(response => {
      dispatch(succeeded(response.data));
			dispatch(notifySuccess("activationcode", "primary"));
    })
    .catch(error => {
      dispatch(failed(error.response));
    });
  };
};