import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import ProductView from './ProductView';

// echelon commons/utilities
import { updatestate } from 'variables/utilities';
import * as enquiry from "store/actions/enquiry";

class Product extends Component {
	constructor(props) {
		super(props);

    this.state = {
      basic: {
        email: ''
      },
      valid: {
        email: false
      },

      error: null,
      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { error, modifiedDate } = props;
    if(modifiedDate > state.modifiedDate) {
      return {
        error: error,
        basic: error !== null ? state.basic : {email: ''},
        valid: error !== null ? state.valid : {email: false},
        modifiedDate: modifiedDate,
      }
    }

    return null;
  }

  onChange = (event, attribute, type, valid=true) => {
		this.setState(updatestate(this.state, event, attribute, type, valid));
  }

  onSubmit = ({token, purpose, version}) => {
    const body = {
      emailId: this.state.basic.email,
      token: token,
      purpose: purpose,
      version: version,
    };

    this.props.enquire(body);
  }

  render() {
    const { basic, valid, error } = this.state;
    return (
      <ProductView 
        {...this.props}
        basic={basic}
        valid={valid}
        manualcaptcha={["600", "601", "602"].includes(error)}
        onChangeHandler={this.onChange}
        onSubmitHandler={this.onSubmit}
      />
    );
  }

}

const mapStateToProps = state => {
	return {
    modifiedDate: state.enquiry.modifiedDate,
    error: state.enquiry.error,
	};
};

const mapDispatchToProps = dispatch => {
	return {
    enquire: (body) => dispatch(enquiry.enquire(body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);