import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import partnersIcon from "assets/img/platform/broker/engagement/section1-client-landlord-data.svg";
import keyContactsIcon from "assets/img/platform/broker/engagement/section1-key-contacts.svg";
import interactionsIcon from "assets/img/platform/broker/engagement/section1-interactions.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"Enrich your business by standardizing your client & landlord data, document all your interactions"}
      items={[
        {
          image: partnersIcon,
          title: "Clients & Landlords",
          description: "Capture baseline requirements including location, space and price preference"
        },
        {
          image: keyContactsIcon,
          title: "Key contacts",
          description: "Capture facilities and utilities information from a set of standard values, create custom data points based on need"
        },
        {
          image: interactionsIcon,
          title: "Interactions",
          description: "Capture all types of conversations with your clients and key contacts (meetings, workshops, calls or simple notes)"
        },
      ]}
    />
  );
}