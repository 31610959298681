import React from "react";
import { FormControl, makeStyles } from "@material-ui/core";
import { emphasize, withStyles } from "@material-ui/core/styles";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";

import styles from "core/assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
const useStyles = makeStyles(styles);

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300]
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  }
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default function CustomBreadcrumbs(props) {
  const { crumbs, onCrumbNavigate, separator="/" } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.formControl}>
    <Breadcrumbs aria-label="breadcrumb" separator={separator} className={"navigationTour-Breadcrumbs"}>
      {crumbs && Object.keys(crumbs).map((key) => {
        const crumb = crumbs[key];
        if(crumb) {
          const { crumbname, crumbvalue, crumbicon } = crumb;
          return (
            <StyledBreadcrumb
              component="a"
              key={key}
              label={crumbvalue ? crumbvalue : crumbname}
              icon={crumbicon ? <crumb.crumbicon fontSize="small" /> : null}
              onClick={() => onCrumbNavigate(crumbs[key], key)}
            />
          );
        }
        return null;
      })}
    </Breadcrumbs>
    </FormControl>
  );
}
