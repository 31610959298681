import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// echelon components and icons
import ListAndImageArea from "views/Website/components/ListAreas/ListAndImageArea";
import defaultImage from "assets/img/implementation/section2-background.png";

import styles from "assets/jss/material-dashboard-pro-react/views/website/listAreaSectionStyles.js";
const useStyles = makeStyles(styles);

export default function ListAreaSection(props) {
  const { position="left", items, image=defaultImage } = props;

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <ListAndImageArea
        position={position}
        alignItems="center"
        backgroundImage={image}
        items={items}
      />
    </div>
  );
}