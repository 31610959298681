import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "views/Website/components/Section/BackgroundSection";
import SectionTop from "./Sections/SectionTop";
import SectionBottom from "./Sections/SectionBottom";

import style from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(style);

export default function Transaction({ ...rest }) {

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        marginTop={false}
        className={classes.backgroundTransaction}
        image={require("assets/img/landing/section1-image3.webp")} 
        title="Climb to new levels of property data maintenance"
        subtitle="Enhance your data management experience with industry standard property management functions to boost revenue and lower cost."
        crumbs={[{crumbname: "Broker platform", crumbpath: "/website/platform", state: {scroll: false}}, 
                  {crumbname: "Climb to new levels of property data maintenance"}]}
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.striped}>
          <div className={classes.container}>
            <SectionTop />
          </div>
        </div>
        <div className={classes.container}>
          <SectionBottom />
        </div>
      </div>
    </div>
  );
}
