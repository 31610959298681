const messages = {
  "999": "One or more values have not been provided or are not correct. Check fields marked with red and try the request again",
  "000": "We have encountered a problem while accessing the server, please try after some time",
  "002": "All mandatory values have not been provided; backend mandatory parameter check failed",
  "003": "The request cannot be performed in the current state; Check the details and retry the request",
  "004": "The entity does not exist; Check the details and retry the request",
  "006": "The code that you have provided does not exist or has expired. Check your email and provide the correct code",
  "007": "The password does not does not match the password rules. Your password must be at least 6 characters long with at least one numeric, one lower case and upper case character",
  "008": "User with the same emailId already exists, use a different emailId and try again",
  "011": "You must add units to your proposal. All units are either removed or there are no units in the proposal.",
  "013": "Exaspaces does not support more than one account in the same browser. Log out of all sessions and login again.",
  "014": "Property unit not available; it may have been removed from the property",
  "015": "The add-on that your trying to activate/use is no longer available",
  "016": "You have consumed all the units for the addon. Request for more units via addon management",
  "502": "Check your username and password. If you still cannot log in, you can reset your password and try again",
  "503": "You have no password. You can reset your password, using the forgot password, and try again",
  "504": "You are not authorized to perform this action, contact your administrator to get the required authorization",
  "506": "Trial account limit has been reached. Please upgrade your account to conitnue",

  "600": "Automatic captcha validation failed, you will be presented with a captcha form for manual validation",
  "601": "Automatic captcha validation failed, you will be presented with a captcha form for manual validation",
  "602": "Automatic captcha validation failed, you will be presented with a captcha form for manual validation",

  "activationcode": "An activation code has been sent to your emailid. Enter the activation code to complete the registration process.",
  "otp": "A one-time passcode has been sent to your emailid, enter the code to procced with the password update",
  "password": "Your password has been reset successfully. You must use the new password the next time you login",
  "file1mb": "The file you are trying to attach is larger than 1 MB. Choose a file smaller than 1 MB",
  "file5mb": "The file you are trying to attach is larger than 5 MB. Choose a file smaller than 5 MB",
  "file10mb": "The file you are trying to attach is larger than 10 MB. Choose a file smaller than 10 MB",

  // success message for the respective entities
  "user": "Your profile has been saved successfully",
  "personalization": "Your personalization settings has been saved successfully",
  "business": "Your profile has been saved successfully",
  "Landlord": "Your landlord has been saved successfully",
  "Client": "Your client has been saved successfully",
  "contact": "Your contact has been saved successfully",
  "building": "Your property information has been saved successfully",
  "employee": "Your employee information has been saved successfully",
  "interaction": "Your interaction information has been saved successfully",
  "attachment": "Your attachment/asset has been saved successfully",
  "attachments": "Your attachments/assets have been saved successfully",
  "tenant": "Your tenant has been saved successfully",
  "requirement": "Your requirement has been saved successfully",
  "unit": "Your unit has been saved successfully",
  "proposal": "Your proposal has been saved successfully",
  "proposalpreview": "A preview of the proposal has been generated, check you email for the link to the proposal",
  "proposalshared": "Your proposal has been shared successfully",
  "mergeproposalunit": "Units has been merged successully",
  "addproposalunit": "Unit has been added to the proposal",
  "proposalunit": "Your proposal unit has been saved successfully",
  "proposalunitrefresh": "Your proposal unit information has been refreshed successfully",
  "proposalcoverasset": "Asset has been marked as a cover asset for the proposal",
  "comment": "Your comments have been saved successfully",
  "settings": "Corporate settings has been saved successfully",
  "addonrequested": "Your request for the addon has been sent to Exaspaces for approval",
  "transfer": "All owned content has been successfully transferred",
  "enquirysuccess": "Your enquiry has been sent to the sales team; they will reach out to you shortly",
  "propertypurchased": "Your have successfully purchased the property",
  "demo": "Thank you for interest in Exaspaces Broker Platform. A member of our sales team will reach out to you at the earliest",

  "deleteLandlord": "Landlord has been removed successfully",
  "deleteClient": "Client has been removed successfully",
  "deletecontact": "Contact has been removed successfully",
  "deletebuilding": "Property has been removed successfully",
  "deleteemployee": "Employee has been removed successfully",
  "deleteinteraction": "Interaction has been removed successfully",
  "deletetenant": "Tenant has been removed successfully",
  "deleterequirement": "Requirement has been removed successfully",
  "deleteunit": "Unit has been removed successfully",
  "deleteproposal": "Proposal has been removed successfully",
  "deleteproposalunit": "Proposal unit has been removed successfully",
 
  "dirty": "You have unsaved changes. You must save your changes before you can proceed",
  "NOTACTIVE": "Your corporate account is not activate, contact Exaspaces to activate your account",
  "logout": "Your session has ended and you have been logged out",
}

export {
  messages
}