import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "../components/Section/BackgroundSection";
import ExploreExaspaces from "./Sections/ExploreExaspaces";
import WhoAreWe from "./Sections/WhoAreWe";

import { useTitle } from "variables/utilities";

import style from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(style);

export default function Vision({ ...rest }) {
  useTitle("Exaspaces platform, smart, intelligent and humanized technology for the real estate industry");

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        className={classes.backgroundVision}
        image={require("assets/img/vision/vision-background.png")} 
        title="Our Vision, your Platform"
        subtitle="Transform the commercial real estate industry with specialized and deep products driven by smart, 
        intelligent and humanized technologies that support best practices to help our customers run 
        their businesses effectively and efficiently."
      />
     <div className={classNames(classes.main, classes.main)}>
        <div className={classes.container}>
          <ExploreExaspaces />
        </div>
        <div className={classes.striped}>
          <div className={classes.container}>
            <WhoAreWe />
          </div>
        </div>
      </div>
    </div>
  );
}
