import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import AddAlert from "@material-ui/icons/AddAlert";

import NoticeView from "layouts/components/NoticeView";
import * as notice from 'store/actions/notice';

class Notice extends React.Component {

  closeNotification = () => {
    this.props.close();
  }

  componentDidMount() {
    this.props.close();
  }

  render() {    
    const { code, color, icon, place, notify } = this.props;

    return (
      <NoticeView
        place={place}
        color={color ? color : "success"}
        icon={icon ? icon : AddAlert}
        code={code ? code : "000"}
        open={notify}
        closeNotification={this.closeNotification}      
      />
    )
  }
}

Notice.defaultProps = {
  code: "999",
  place: "tc",
  color: "success",
  icon: AddAlert
};

Notice.propTypes = {
  code: PropTypes.node,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose"
  ]),
  icon: PropTypes.object,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
  reset: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    notify: state.notice.notify,
    code: state.notice.code,
    color: state.notice.color
  };
};

const mapDispatchToProps = dispatch => {
  return {
    show: (code, color="success") => dispatch(notice.show(code, color)),
    close: () => dispatch(notice.hide()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice);