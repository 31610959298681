import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import listItemIcon from "assets/img/platform/broker/engagement/section2-client-landlord.svg";

import clientLandlordImage from "assets/img/platform/broker/engagement/section2-client-landlord-data.png";
import interctionImage from "assets/img/platform/broker/engagement/section2-interactions.png";

export default function SectionBottom(props) {
  const { position="top left" } = props;

  return (
    <ListAreaSection 
      position={position}
      image={null}
      items={[
        {
          primary: "Foundation for client and landlord data",
          secondary: "Holistic client and landlord information with inbuilt governance and access credentials",
          icon: listItemIcon,
          image: clientLandlordImage
        },
        {
          primary: "Multi-dimensional view of client and landlord data",
          secondary: "Key contacts including important primary contacts, interactions (list and calendar views) with the flexibility to capture any additional information for future reference",
          icon: listItemIcon,
          image: interctionImage
        },
      ]}
    />
  );
}