import axios from "store/axios-broker";
import { NOTICE_SHOW, AUTHORIZE_LOGOUT } from 'store/types';

import createStores from "store/stores";

// local definitions
const store = createStores();
const { dispatch } = store;
let refreshPromise = null;

const notifyFailure = (code) => {
  return dispatch => {
    dispatch({
      type: NOTICE_SHOW,
      code: code,
      color: "danger"
    });
  };
};

export const logout = () => {
  ['authorized', 'token', 'userId', 'businessId', 'freetrial',
    'role', 'modules', 'features', 'readonlymodules', 'propertymoduletypes'].forEach(element => {
    localStorage.removeItem(element);
  });

  return dispatch => {
    dispatch({
      type: AUTHORIZE_LOGOUT
    });
  };
};

const querystring = require('querystring');
const performAccessTokenRefresh = (resolve, reject) => {
  const userId = localStorage.getItem('userId');  
  if(userId !== null) {
    axios.post("/apex/certify/token/refresh/" + userId, 
                querystring.stringify({userId: userId}), 
                {urlencoded: true, refreshrequest: true}
    ).then(response => {
      const { access_token } = response.data;
      if(process.env.NODE_ENV === "development" && access_token !== null) {
        localStorage.setItem('token', access_token);
      }
		}).catch(() => {
      reject();
    });

    return true;
  }
  reject();
}

const makeRefreshTokenPromise = () => {
  if(refreshPromise === null) {
    refreshPromise = new Promise((resolve, reject) => {
      performAccessTokenRefresh(resolve, reject);
      setTimeout(() => {
        refreshPromise = null;
        resolve();
      }, 1000);
    });
  }

  return refreshPromise;
}

const makeRefreshAccessToken = (error, code) => {
  const original = error.config;
  
  // should we attemtp to refresh the access token
  if((code === "505" || code === "502") 
        && !original._retry && original.refreshrequest !== true) {

    // set the retry for the original request
    original._retry = true; 

    // perform the refresh and return promise with original request 
    // or send promise with reject 
    return makeRefreshTokenPromise()
    .then(() => {
      refreshPromise = null;
      return axios(error.config);
    }).catch(() => {
      refreshPromise = null;
      return Promise.reject(error);
    })
  }

  // not working??? logout and send a logout message
  dispatch(logout());
  dispatch(notifyFailure("logout"))
  return Promise.reject(error);
}

const interceptor = (error) => {
  const { data, status } = error.response ? error.response : {};
  const code = data && data.code ? data.code : "000";
  if(status === 401 && error.config.login !== true) {
    return makeRefreshAccessToken(error, code);
  }

  dispatch(notifyFailure(code));
  return Promise.reject(error);
}

export default interceptor;