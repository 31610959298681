import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  showresult: false, request: null,
  loading: false, error: null
};
  
const started = (state, action) => {
  return update( state, {
    showresult: false,
    request: action.request,
    error: null, loading: true 
  });
};

const succeeded = (state, action) => {
  return update( state, {
    showresult: true,
    error: null, loading: false
  });
};

const failed = (state, action) => {
  return update( state, {
    error: action.error, loading: false
  });
}

const reducer = (state = initial, action) => {
  switch ( action.type ) {
    case types.CONFIRMREGISTER_START: 
      return started(state, action);

    case types.CONFIRMREGISTER_SUCCESS: 
      return succeeded(state, action);

    case types.CONFIRMREGISTER_FAILURE: 
      return failed(state, action);

    default:
      return state;
  }
};

export default reducer;