import React, { Fragment } from "react";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ArrowRight from "@material-ui/icons/ArrowRight";

// core components
import Parallax from "core/components/Parallax/Parallax.js";
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import Button from "core/components/CustomButtons/Button.js";

// echelon components
import CustomBreadcrumbs from "common/components/CustomBreadcrumbs";

import style from "assets/jss/material-dashboard-pro-react/views/website/backgroundSectionStyles.js";
const useStyles = makeStyles(style);

export default function BackgroundSection(props) {
  const { image, title, subtitle, className, marginTop=true, landing=false, 
          color="purple", url="/website/signup", buttonText="Get started", crumbs, history } = props;

  const navigate = (crumb, key) => {
    if(crumb.crumbpath) {
      history.push(crumb.crumbpath, crumb.state);
    }
  }

  const classes = useStyles();
  const titleClasses = classNames({
    [classes.title]: true,
    [classes.titleMargin]: marginTop,
    [classes.titleLanding]: landing,
  });

  return (
    <Fragment>
      <Parallax className={className} image={image} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              {crumbs &&
              <CustomBreadcrumbs
                separator={<ArrowRight fontSize="large" style={{color: "white"}} />}
                crumbs={crumbs}
                onCrumbNavigate={navigate}
              />
              }
              <h1 className={titleClasses}>{title}</h1>
              <h2 className={classes.subtitle}>{subtitle}</h2>
              <Button
                color={color}
                size="lg"
                className={classes.textBold}
                onClick={()=>{history.push(url)}}
              >
                {buttonText}
              </Button>
            </GridItem>
          </GridContainer>
      </div>
      </Parallax>
    </Fragment>
  )
}
