import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import listItemIcon from "assets/img/platform/broker/fasttimevalue/section2-proposals.svg";

import clientProposalImage from "assets/img/platform/broker/fasttimevalue/section2-client-proposal.png";
import brokerProposalImage from "assets/img/platform/broker/fasttimevalue/section2-broker-proposal.png";
import searchAndRecommendImage from "assets/img/platform/broker/fasttimevalue/section2-search-recommend.png";

export default function SectionBottom(props) {
  const { position="top left" } = props;

  return (
    <ListAreaSection 
      position={position}
      image={null}
      items={[
        {
          primary: "Collaborative client proposal",
          secondary: "Visual proposal for clients that encompasses multiple data dimensions and collaboration functions",
          icon: listItemIcon,
          image: clientProposalImage
        },
        {
          primary: "Proposal for Brokers",
          secondary: "Instant and customer requirements based proposals with the ability to add units with speed and precision. Collaborate with internal experts before sharing the same with clients",
          icon: listItemIcon,
          image: brokerProposalImage
        },
        {
          primary: "Search and Recommend",
          secondary: "Visual and textual search to ease identification of units. Recommendations to speed up identification of the right units based on various features and dimensions",
          icon: listItemIcon,
          image: searchAndRecommendImage
        },
      ]}
    />
  );
}