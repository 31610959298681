import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";

// echelon components and icons
import StackedInfoCard from "views/Website/components/InfoCard/StackedInfoCard";

import styles from "assets/jss/material-dashboard-pro-react/views/website/infoCardSectionStyles";
const useStyles = makeStyles(styles);

export default function InfoCardSection(props) {
  const { title, items } = props;

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <h3 className={classes.title}>{title}</h3>
      </div>
      <div>
        <GridContainer justifyContent="center">
          {items.map((item, key) => {
            return (
              <GridItem key={key} xs={12} sm={4} md={3}>
                <StackedInfoCard
                  image={item.image}
                  title={item.title}
                  description={item.description}
                />
              </GridItem>
            )}
          )}
        </GridContainer>
      </div>
    </div>
  );
}