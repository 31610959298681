// function that verifies if the contents is an email address
const verifyEmail = (value, mandatory=true) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((!mandatory && value.trim().length === 0) || emailRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if the string has the required length
const verifyLength = (value, length) => {
    if (value.trim().length >= length) {
      return true;
    }
    return false;
  };

// function that verifies if value contains only numbers
const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (value.trim().length === 0 || numberRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if value contains a ratio
const verifyRatio = value => {
  const values = value.split(":");  
  const valid = values.length <= 2 ? verifyNumber(values[0]) : false;
  if(valid && values.length === 2) {
    return valid && verifyNumber(values[1]);
  }
  return valid;
};

const verifyMobile = value => {
    var numberRex = new RegExp("^[0-9]{10}$");
    if (value.trim().length === 0 || numberRex.test(value)) {
      return true;
    }
    return false;
};

const verifyPhone = (value, mandatory) => {
    var numberRex = new RegExp("^[0-9]{8,12}$");
    if ((!mandatory && value.trim().length === 0) || numberRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if value contains only numbers
const verifyPassword = value => {
    var passwordRex = new RegExp("^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])).{6,15}$");
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
};

const verifyPasswordEquals = (value, other) => {
    var passwordRex = new RegExp("^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])).{6,15}$");
    if (passwordRex.test(value)) {
      return value === other;
    }
    return false;
};

// verifies if value is a valid URL
const verifyUrl = value => {
    try {
      if(value && value.trim().length > 0) {
        new URL(value);
      }
    } catch (_) {
      return false;
    }
    return true;
};

const verifyWebsite = (value) => {
  const valid = verifyUrl(value);
  if(valid === false) {
    var website = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return website.test(value);
  }
  return valid;
}

const updateCustomAttribute = (attributes, index, name, value, type='text') => {
  const clone = attributes ? [...attributes] : [];
  if(index >=0) {
    clone[index] = {name: name, value: value, type: type};
  } else {
    clone.push({name: name, value: value, type: type});
  }

  return clone;
}

const updatestate = (state, event, attribute, type, valid) => {
  if(attribute) {
    const value = event && event.target ? event.target.value : event;
    const updatedObject = update(state[type], {
            [attribute]: value } );

    const updatedValidity = update(state.valid, {
            [attribute]:  valid});
            
    return { [type]: updatedObject,
              valid: updatedValidity,
              dirty: true };      
  } else {
    const updatedValidity = update(state.valid, {
              [type]:  valid});
    
    return { [type]: event,
              valid: updatedValidity,
              dirty: true }; 
  }
}

const update = (object, properties) => {
  return {
    ...object,
    ...properties
  };
};

const constainsValue = (source, term) => {
  if(Array.isArray(term)) {
    return source.some((value) => term.indexOf(value) >= 0);
  }

  return source.includes(term);
}

const forceOnChange = (input, value) => {
  const previousValue = input.value;

  // eslint-disable-next-line no-param-reassign
  input.value = value;

  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(previousValue);
  }

  // 'change' instead of 'input', see https://github.com/facebook/react/issues/11488#issuecomment-381590324
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

const mergeTags = (options, selected) => {
  let merged = options;
  if(selected && selected !== '') {
    if(!options.includes(selected)) {
      merged = [selected, ...options];
    }
  }
  return merged;
}

const mergeAllTags = (options, selected) => {
  let merged = options;
  if(selected && selected.length > 0) {
      merged = [...new Set([...selected, ...options])];
  }
  return merged;
}

export {
    // validation utility methods
    verifyEmail,
    verifyLength,
    verifyNumber,
    verifyRatio,
    verifyPhone,
    verifyMobile,
    verifyPassword,
    verifyPasswordEquals,
    verifyUrl,
    verifyWebsite,

    constainsValue,
    mergeTags,
    mergeAllTags,
    forceOnChange,
    update,
    updatestate,
    updateCustomAttribute,
};
