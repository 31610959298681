import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import classNames from "classnames";

// Sections for this page
import BackgroundSection from "../components/Section/BackgroundSection";
import SectionTop from "./Sections/SectionTop";
import SectionBottom from "./Sections/SectionBottom";

import { useTitle } from "variables/utilities";

import styles from "assets/jss/material-dashboard-pro-react/views/website/landingPageStyle.js";
const useStyles = makeStyles(styles);

export default function Implementation({ ...rest }) {
  useTitle("Exaspaces platform, training programs and implementation services to enable quick and efficient adoption for property consultants");

  const classes = useStyles();
  return (
    <div>
      <BackgroundSection
        {...rest}
        className={classes.backgroundImplementation}
        image={require("assets/img/implementation/implementation-background.png")} 
        title="End-to-End implementation services to help customers embrace Exaspaces"
        subtitle="Our end-to-end implementation services augmented by Training programs enable our customers to 
        adopt Exaspaces with ease and in quick time."
      />
      <div className={classNames(classes.main, classes.main)}>
        <div className={classes.striped}>
          <div className={classes.container}>
            <SectionTop />
          </div>
        </div>
        <div className={classes.container}>
          <SectionBottom />
        </div>
      </div>
    </div>
  );
}
