import React from "react";

// echelon components and icons
import InfoSection from "views/Website/components/Section/InfoCardSection";

import requirementsImage from "assets/img/platform/broker/datacapture/section1-requirements.svg";
import facilitiesImage from "assets/img/platform/broker/datacapture/section1-facilities.svg";
import documentationImage from "assets/img/platform/broker/datacapture/section1-documentation.svg";

export default function SectionTop(props) {
  return (
    <InfoSection
      title={"Explore the Client requirements 360"}
      items={[
        {
          image: requirementsImage,
          title: "Requirements",
          description: "Capture baseline requirements including location, space and price preference"
        },
        {
          image: facilitiesImage,
          title: "Facilities and Utilities",
          description: "Capture facilities and utilities information from a set of standard values, create custom data points based on need"
        },
        {
          image: documentationImage,
          title: "Documentation",
          description: "Capture all types of documents e.g. comprehensive requirement document, interior expectations, etc"
        },
      ]}
    />
  );
}