import {
  hexToRgb,
  whiteColor,
  blackColor,
  grayColor,
  warningColor
} from "core/assets/jss/material-dashboard-pro-react.js";

const productStyle = theme => ({
  section: {
    textAlign: "center"
  },
  cardContainer: {
    zIndex: "4",
    margin: 0,
    marginTop: "-96px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-96px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-132px"
    },
  },
  secondMessageContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "16px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "-48px"
    },
  },
  cardBackgroud: {
    textAlign: "left",
    maxWidth: "460px",
    color: whiteColor,
    backgroundColor: blackColor
  },
  headerTitle: {
    margin: "0",
    padding: "0",
    lineHeight: "2.5rem",
    fontWeight: "400",
  },
  headerSubTitle: {
    margin: "0",
    padding: "0",
    fontWeight: "300",
  },
  buttonContainer: {
    padding: "10px",
    marginBottom: "8px",
    borderRadius: "4px",
    backgroundColor: whiteColor,
  },
  buttonContainerLarge: {
    padding: "24px 0px 24px 10px",
    background: grayColor[0]
  },
  submitButton: {
    color: whiteColor,
    backgroundColor: warningColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[3],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(warningColor[4]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(warningColor[4]) +
        ", 0.2)"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "96px",
    },
  },
  title: {
    // ...title,
    color: blackColor,
    margin: "0",
    marginTop: "0",
    marginBottom: "0",
    padding: "0",
    minHeight: "32px",
    fontSize: "3em",
    fontWeight: "500",
    textAlign: "left",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-18px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "-36px",
    },
  },
  description: {
    color: blackColor,
    margin: "0",
    marginTop: "4px",
    marginBottom: "4px",
    textAlign: "left",
    fontWeight: "300",
    lineHeight: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "90%",
    },
  },
});

export default productStyle;
