import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import backgroundImage from "assets/img/technology/section2-background.png";
import usersImage from "assets/img/technology/section2-users.png";
import addonsImage from "assets/img/technology/section2-addons.png";

import adminImage from "assets/img/technology/section2-marketplace.svg";
import markedPlaceImage from "assets/img/technology/section2-secure.svg";


export default function SectionBottom(props) {
  const { position="top left" } = props;

  return (
    <ListAreaSection 
      position={position}
      image={backgroundImage}
      items={[
        {
          primary: "The marketplace approach",
          secondary: "Flexible approach that allows customers to pick what they want and define the roadmap for consuming the platform’s features and functions",
          icon: adminImage,
          image: addonsImage
        },
        {
          primary: "Secure and Manageable",
          secondary: "Access controls that defines access to enterprise data, resulting in a hypersimple platform for customers from a data perspective",
          icon: markedPlaceImage,
          image: usersImage
        }
      ]}
    />
  );
}