/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

// redux and thunk
import createStores from "store/stores";
import { Provider } from 'react-redux';

// import your fontawesome library
import App from './App.js';
import axios from "store/axios-broker";
import interceptor from "session";

import "core/assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

// reducers and browser history
const store = createStores();
const history = createBrowserHistory();

const app = (
  <Provider store={store}>
    <Router history={history}>
        <App />
    </Router>
  </Provider>
);

axios.interceptors.response.use((response) => {
	return response;
}, (error) => {
  return interceptor(error);
});

ReactDOM.render(app, document.getElementById("root"));
