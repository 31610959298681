import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

// core components
import GridContainer from "core/components/Grid/GridContainer";
import GridItem from "core/components/Grid/GridItem";
import Button from "core/components/CustomButtons/Button";

// echelon components and icons

import styles from "assets/jss/material-dashboard-pro-react/views/website/infoDetailSectionStyles";
const useStyles = makeStyles(styles);

function TextDetailView (props) {
  const { title, description, textBackground, position, url, history } = props;

  const navigate = () => {
    if(url) {
      history.push(url);
    }
  }

  const classes = useStyles();
  return (
    <GridContainer justifyContent="center" alignItems="center">
      <GridItem xs={10} sm={10} md={10}>
        <div 
          className={classes.textViewBackground} 
          style={textBackground &&
                  {backgroundImage: "url(" + textBackground + ")",
                  backgroundPosition: position}}>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.description}>{description}</p>
          {url &&
          <Button 
            color="warning" 
            className={classes.knowMoreButton}
            onClick={navigate}
            >Know More
          </Button>
          }
        </div>
      </GridItem>
    </GridContainer>
  );
}

function ImageDetailView (props) {
  const { image, imageBackground, position } = props;

  const classes = useStyles();
  return (
    <GridContainer justifyContent="center" alignItems="center">
      <GridItem  xs={12} sm={12} md={12}>
        <div 
          className={classes.imageViewBackground} 
          style={imageBackground && 
                  {backgroundImage: "url(" + imageBackground + ")" ,
                  backgroundPosition: position}}>
          <img
            className={classes.image}
            src={image} 
            alt="value proposition" 
          />
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default function InfoDetailSection(props) {
  const { swap=false } = props;

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer alignItems="center">
        <GridItem  xs={12} sm={12} md={6}>
          <Hidden smDown implementation="css">
            {swap ? <ImageDetailView {...props} /> : <TextDetailView {...props} />}
          </Hidden>
          <Hidden mdUp implementation="css">
            <TextDetailView {...props} />           
          </Hidden>
        </GridItem>
        <GridItem  xs={12} sm={12} md={6}>
          <Hidden smDown implementation="css">
            {swap ? <TextDetailView {...props} /> : <ImageDetailView {...props} />}
          </Hidden>
          <Hidden mdUp implementation="css">
            <ImageDetailView {...props} />           
          </Hidden>
        </GridItem>
      </GridContainer> 
   </div>
  );
}