import React from "react";

// echelon components and icons
import ListAreaSection from "views/Website/components/Section/ListAreaSection";

import listItemIcon from "assets/img/platform/broker/datacapture/section2-requirements.svg";

import requirementsImage from "assets/img/platform/broker/datacapture/section2-requirements.png";
import levelDataImage from "assets/img/platform/broker/datacapture/section2-level-data.png";

export default function SectionBottom(props) {
  const { position="top left" } = props;

  return (
    <ListAreaSection 
      position={position}
      image={null}
      items={[
        {
          primary: "Standardise client requirements",
          secondary: "Move away from Excel sheets or documents and capture all aspects of client requirements in a standardized manner",
          icon: listItemIcon,
          image: requirementsImage
        },
        {
          primary: "Capture all levels of details",
          secondary: "Extremely flexible data capture environment that will allow you to maintain standard and custom data",
          icon: listItemIcon,
          image: levelDataImage
        },
      ]}
    />
  );
}