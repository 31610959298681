import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, Input, InputAdornment } from "@material-ui/core";

// echelon images
import valuePropImage1 from "assets/img/landing/section1-image1.webp";
import valuePropImage2 from "assets/img/landing/section1-image2.webp";
import valuePropImage3 from "assets/img/landing/section1-image3.webp";
import valuePropImage4 from "assets/img/landing/section1-image4.webp";

import fastTimeToValueIcon from "assets/img/landing/section1-fast-time-value.svg";
import consistentDataIcon from "assets/img/landing/section1-consistent-date.svg";
import scalableIcon from "assets/img/landing/section1-scalable.svg";
import singleSourceIcon from "assets/img/landing/section1-single-source.svg";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import Card from "core/components/Card/Card";
import CardBody from "core/components/Card/CardBody";
import CardHeader from "core/components/Card/CardHeader";

// echelon component
import ListAndImageArea from "views/Website/components/ListAreas/ListAndImageArea";
import RecaptchaButton from "common/components/Captcha/RecaptchaButton";

import { verifyEmail } from "core/utils/utilities";

import productStyle from "assets/jss/material-dashboard-pro-react/views/website/productSectionStyle";
const useStyles = makeStyles(productStyle);

export default function ProductView(props) {
  const { basic, valid, manualcaptcha=false, onChangeHandler, onSubmitHandler } = props;

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer className={classes.cardContainer} justifyContent="flex-end">
        <GridItem xs={12} sm={9} md={7} lg={5}>
          <Card className={classes.cardBackgroud}>
            <CardHeader>
              <h2 className={classes.headerTitle}>Have any questions?</h2>
              <h5 className={classes.headerSubTitle}>Just enter your e-mail and we'll get in touch with you</h5>
            </CardHeader>
            <CardBody>
              <div className={classes.buttonContainer + " " + (manualcaptcha && classes.buttonContainerLarge)}>
                <Input 
                  fullWidth
                  disableUnderline
                  placeholder="youremail@domain.com"
                  type="email"
                  endAdornment={<InputAdornment position="end">
                                  <RecaptchaButton
                                    Button={Button}
                                    purpose="inquiry"
                                    manualcaptcha={manualcaptcha}
                                    disabled={valid.email === false}
                                    onClick={onSubmitHandler}
                                    className={classes.submitButton}>
                                      Submit
                                  </RecaptchaButton>
                                </InputAdornment>}
                  onChange={(event) => {
                    const valid = verifyEmail(event.target.value);
                    onChangeHandler(event, 'email', 'basic', valid)
                  }}
                  value={basic.email}
                />
              </div>
                This site is protected by reCAPTCHA and the Google 
                <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank"> Privacy Policy </a> and 
                <a href="https://policies.google.com/terms" rel="noopener noreferrer"target="_blank"> Terms of Service </a> apply.
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.secondMessageContainer}>
        <GridItem xs={12} sm={12} md={8} lg={6}>
          <h2 className={classes.title}>Built to scale your business</h2>
          <h5 className={classes.description}>
            A comprehensive platform to manage your Property Consulting Business in a Seamless, 
            Simplified and Transformative way!
          </h5>
        </GridItem>
      </GridContainer>
      <ListAndImageArea
        position="bottom left"
        items={[
          {
            primary: "Fast time to value",
            secondary: "Collaborative proposal management with clients",
            icon: fastTimeToValueIcon,
            image: valuePropImage1
          },
          {
            primary: "Comprehensive and consistent data",
            secondary: "Document client requirements with ease",
            icon: consistentDataIcon,
            image: valuePropImage2
          },
          {
            primary: "Single source of truth",
            secondary: "Standardized Property, Client and Landlord data",
            icon: singleSourceIcon,
            image: valuePropImage3
          },
          {
            primary: "Scalable, Flexible and Intelligent",
            secondary: "Solution for any size company, intelligent recommendations, amazing ROI",
            icon: scalableIcon,
            image: valuePropImage4
          },
        ]}
      />
    </div>
  );
}
